// source: email/v1/email.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var booking_v1_booking_pb = require('../../booking/v1/booking_pb.js');
goog.object.extend(proto, booking_v1_booking_pb);
var contract_v1_contract_pb = require('../../contract/v1/contract_pb.js');
goog.object.extend(proto, contract_v1_contract_pb);
var messaging_v1_messaging_pb = require('../../messaging/v1/messaging_pb.js');
goog.object.extend(proto, messaging_v1_messaging_pb);
var order_v1_order_pb = require('../../order/v1/order_pb.js');
goog.object.extend(proto, order_v1_order_pb);
var document_v1_document_pb = require('../../document/v1/document_pb.js');
goog.object.extend(proto, document_v1_document_pb);
var invoicing_v1_invoice_pb = require('../../invoicing/v1/invoice_pb.js');
goog.object.extend(proto, invoicing_v1_invoice_pb);
var shipment_v1_shipment_pb = require('../../shipment/v1/shipment_pb.js');
goog.object.extend(proto, shipment_v1_shipment_pb);
goog.exportSymbol('proto.swift.email.v1.Attachment', null, global);
goog.exportSymbol('proto.swift.email.v1.BookingAccepted', null, global);
goog.exportSymbol('proto.swift.email.v1.BookingBooked', null, global);
goog.exportSymbol('proto.swift.email.v1.BookingCreated', null, global);
goog.exportSymbol('proto.swift.email.v1.BookingEdited', null, global);
goog.exportSymbol('proto.swift.email.v1.BookingImportFailed', null, global);
goog.exportSymbol('proto.swift.email.v1.BookingQuote', null, global);
goog.exportSymbol('proto.swift.email.v1.ContractAccepted', null, global);
goog.exportSymbol('proto.swift.email.v1.DocumentUploaded', null, global);
goog.exportSymbol('proto.swift.email.v1.Email', null, global);
goog.exportSymbol('proto.swift.email.v1.Email.EventCase', null, global);
goog.exportSymbol('proto.swift.email.v1.Email.Type', null, global);
goog.exportSymbol('proto.swift.email.v1.InvoiceIssued', null, global);
goog.exportSymbol('proto.swift.email.v1.InvoiceOverdue', null, global);
goog.exportSymbol('proto.swift.email.v1.MessageCreated', null, global);
goog.exportSymbol('proto.swift.email.v1.OrderCreated', null, global);
goog.exportSymbol('proto.swift.email.v1.OrderUpdated', null, global);
goog.exportSymbol('proto.swift.email.v1.ShipmentBookingAdded', null, global);
goog.exportSymbol('proto.swift.email.v1.ShipmentBookingRemoved', null, global);
goog.exportSymbol('proto.swift.email.v1.ShipmentCreated', null, global);
goog.exportSymbol('proto.swift.email.v1.ShipmentEdited', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.BookingImportFailed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.BookingImportFailed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.BookingImportFailed.displayName = 'proto.swift.email.v1.BookingImportFailed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.BookingCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.BookingCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.BookingCreated.displayName = 'proto.swift.email.v1.BookingCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.BookingEdited = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.BookingEdited, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.BookingEdited.displayName = 'proto.swift.email.v1.BookingEdited';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.BookingAccepted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.BookingAccepted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.BookingAccepted.displayName = 'proto.swift.email.v1.BookingAccepted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.BookingBooked = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.BookingBooked, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.BookingBooked.displayName = 'proto.swift.email.v1.BookingBooked';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.MessageCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.MessageCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.MessageCreated.displayName = 'proto.swift.email.v1.MessageCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.OrderCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.OrderCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.OrderCreated.displayName = 'proto.swift.email.v1.OrderCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.OrderUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.OrderUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.OrderUpdated.displayName = 'proto.swift.email.v1.OrderUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.DocumentUploaded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.DocumentUploaded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.DocumentUploaded.displayName = 'proto.swift.email.v1.DocumentUploaded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.InvoiceIssued = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.InvoiceIssued, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.InvoiceIssued.displayName = 'proto.swift.email.v1.InvoiceIssued';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.InvoiceOverdue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.InvoiceOverdue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.InvoiceOverdue.displayName = 'proto.swift.email.v1.InvoiceOverdue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.BookingQuote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.BookingQuote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.BookingQuote.displayName = 'proto.swift.email.v1.BookingQuote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.Attachment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.Attachment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.Attachment.displayName = 'proto.swift.email.v1.Attachment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.ShipmentCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.ShipmentCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.ShipmentCreated.displayName = 'proto.swift.email.v1.ShipmentCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.ShipmentEdited = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.ShipmentEdited, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.ShipmentEdited.displayName = 'proto.swift.email.v1.ShipmentEdited';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.ShipmentBookingAdded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.ShipmentBookingAdded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.ShipmentBookingAdded.displayName = 'proto.swift.email.v1.ShipmentBookingAdded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.ShipmentBookingRemoved = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.ShipmentBookingRemoved, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.ShipmentBookingRemoved.displayName = 'proto.swift.email.v1.ShipmentBookingRemoved';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.ContractAccepted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.email.v1.ContractAccepted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.ContractAccepted.displayName = 'proto.swift.email.v1.ContractAccepted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.email.v1.Email = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.swift.email.v1.Email.oneofGroups_);
};
goog.inherits(proto.swift.email.v1.Email, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.email.v1.Email.displayName = 'proto.swift.email.v1.Email';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.BookingImportFailed.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.BookingImportFailed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.BookingImportFailed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.BookingImportFailed.toObject = function(includeInstance, msg) {
  var f, obj = {
    ediFilename: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.BookingImportFailed}
 */
proto.swift.email.v1.BookingImportFailed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.BookingImportFailed;
  return proto.swift.email.v1.BookingImportFailed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.BookingImportFailed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.BookingImportFailed}
 */
proto.swift.email.v1.BookingImportFailed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdiFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.BookingImportFailed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.BookingImportFailed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.BookingImportFailed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.BookingImportFailed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEdiFilename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string edi_filename = 1;
 * @return {string}
 */
proto.swift.email.v1.BookingImportFailed.prototype.getEdiFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.email.v1.BookingImportFailed} returns this
 */
proto.swift.email.v1.BookingImportFailed.prototype.setEdiFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.BookingCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.BookingCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.BookingCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.BookingCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    booking: (f = msg.getBooking()) && booking_v1_booking_pb.Booking.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.BookingCreated}
 */
proto.swift.email.v1.BookingCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.BookingCreated;
  return proto.swift.email.v1.BookingCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.BookingCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.BookingCreated}
 */
proto.swift.email.v1.BookingCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new booking_v1_booking_pb.Booking;
      reader.readMessage(value,booking_v1_booking_pb.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.BookingCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.BookingCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.BookingCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.BookingCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      booking_v1_booking_pb.Booking.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional swift.booking.v1.Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.email.v1.BookingCreated.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, booking_v1_booking_pb.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.email.v1.BookingCreated} returns this
*/
proto.swift.email.v1.BookingCreated.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.BookingCreated} returns this
 */
proto.swift.email.v1.BookingCreated.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.BookingCreated.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string created_by = 2;
 * @return {string}
 */
proto.swift.email.v1.BookingCreated.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.email.v1.BookingCreated} returns this
 */
proto.swift.email.v1.BookingCreated.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.BookingEdited.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.BookingEdited.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.BookingEdited} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.BookingEdited.toObject = function(includeInstance, msg) {
  var f, obj = {
    booking: (f = msg.getBooking()) && booking_v1_booking_pb.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.BookingEdited}
 */
proto.swift.email.v1.BookingEdited.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.BookingEdited;
  return proto.swift.email.v1.BookingEdited.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.BookingEdited} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.BookingEdited}
 */
proto.swift.email.v1.BookingEdited.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new booking_v1_booking_pb.Booking;
      reader.readMessage(value,booking_v1_booking_pb.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.BookingEdited.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.BookingEdited.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.BookingEdited} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.BookingEdited.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      booking_v1_booking_pb.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.booking.v1.Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.email.v1.BookingEdited.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, booking_v1_booking_pb.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.email.v1.BookingEdited} returns this
*/
proto.swift.email.v1.BookingEdited.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.BookingEdited} returns this
 */
proto.swift.email.v1.BookingEdited.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.BookingEdited.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.BookingAccepted.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.BookingAccepted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.BookingAccepted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.BookingAccepted.toObject = function(includeInstance, msg) {
  var f, obj = {
    booking: (f = msg.getBooking()) && booking_v1_booking_pb.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.BookingAccepted}
 */
proto.swift.email.v1.BookingAccepted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.BookingAccepted;
  return proto.swift.email.v1.BookingAccepted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.BookingAccepted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.BookingAccepted}
 */
proto.swift.email.v1.BookingAccepted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new booking_v1_booking_pb.Booking;
      reader.readMessage(value,booking_v1_booking_pb.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.BookingAccepted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.BookingAccepted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.BookingAccepted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.BookingAccepted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      booking_v1_booking_pb.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.booking.v1.Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.email.v1.BookingAccepted.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, booking_v1_booking_pb.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.email.v1.BookingAccepted} returns this
*/
proto.swift.email.v1.BookingAccepted.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.BookingAccepted} returns this
 */
proto.swift.email.v1.BookingAccepted.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.BookingAccepted.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.BookingBooked.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.BookingBooked.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.BookingBooked} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.BookingBooked.toObject = function(includeInstance, msg) {
  var f, obj = {
    booking: (f = msg.getBooking()) && booking_v1_booking_pb.Booking.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.BookingBooked}
 */
proto.swift.email.v1.BookingBooked.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.BookingBooked;
  return proto.swift.email.v1.BookingBooked.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.BookingBooked} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.BookingBooked}
 */
proto.swift.email.v1.BookingBooked.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new booking_v1_booking_pb.Booking;
      reader.readMessage(value,booking_v1_booking_pb.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.BookingBooked.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.BookingBooked.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.BookingBooked} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.BookingBooked.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      booking_v1_booking_pb.Booking.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional swift.booking.v1.Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.email.v1.BookingBooked.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, booking_v1_booking_pb.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.email.v1.BookingBooked} returns this
*/
proto.swift.email.v1.BookingBooked.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.BookingBooked} returns this
 */
proto.swift.email.v1.BookingBooked.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.BookingBooked.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string created_by = 2;
 * @return {string}
 */
proto.swift.email.v1.BookingBooked.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.email.v1.BookingBooked} returns this
 */
proto.swift.email.v1.BookingBooked.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.MessageCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.MessageCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.MessageCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.MessageCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && messaging_v1_messaging_pb.Message.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.MessageCreated}
 */
proto.swift.email.v1.MessageCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.MessageCreated;
  return proto.swift.email.v1.MessageCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.MessageCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.MessageCreated}
 */
proto.swift.email.v1.MessageCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new messaging_v1_messaging_pb.Message;
      reader.readMessage(value,messaging_v1_messaging_pb.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.MessageCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.MessageCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.MessageCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.MessageCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      messaging_v1_messaging_pb.Message.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.messaging.v1.Message message = 1;
 * @return {?proto.swift.messaging.v1.Message}
 */
proto.swift.email.v1.MessageCreated.prototype.getMessage = function() {
  return /** @type{?proto.swift.messaging.v1.Message} */ (
    jspb.Message.getWrapperField(this, messaging_v1_messaging_pb.Message, 1));
};


/**
 * @param {?proto.swift.messaging.v1.Message|undefined} value
 * @return {!proto.swift.email.v1.MessageCreated} returns this
*/
proto.swift.email.v1.MessageCreated.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.MessageCreated} returns this
 */
proto.swift.email.v1.MessageCreated.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.MessageCreated.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.OrderCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.OrderCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.OrderCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.OrderCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && order_v1_order_pb.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.OrderCreated}
 */
proto.swift.email.v1.OrderCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.OrderCreated;
  return proto.swift.email.v1.OrderCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.OrderCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.OrderCreated}
 */
proto.swift.email.v1.OrderCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new order_v1_order_pb.Order;
      reader.readMessage(value,order_v1_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.OrderCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.OrderCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.OrderCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.OrderCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      order_v1_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.order.v1.Order order = 1;
 * @return {?proto.swift.order.v1.Order}
 */
proto.swift.email.v1.OrderCreated.prototype.getOrder = function() {
  return /** @type{?proto.swift.order.v1.Order} */ (
    jspb.Message.getWrapperField(this, order_v1_order_pb.Order, 1));
};


/**
 * @param {?proto.swift.order.v1.Order|undefined} value
 * @return {!proto.swift.email.v1.OrderCreated} returns this
*/
proto.swift.email.v1.OrderCreated.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.OrderCreated} returns this
 */
proto.swift.email.v1.OrderCreated.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.OrderCreated.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.OrderUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.OrderUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.OrderUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.OrderUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && order_v1_order_pb.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.OrderUpdated}
 */
proto.swift.email.v1.OrderUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.OrderUpdated;
  return proto.swift.email.v1.OrderUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.OrderUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.OrderUpdated}
 */
proto.swift.email.v1.OrderUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new order_v1_order_pb.Order;
      reader.readMessage(value,order_v1_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.OrderUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.OrderUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.OrderUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.OrderUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      order_v1_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.order.v1.Order order = 1;
 * @return {?proto.swift.order.v1.Order}
 */
proto.swift.email.v1.OrderUpdated.prototype.getOrder = function() {
  return /** @type{?proto.swift.order.v1.Order} */ (
    jspb.Message.getWrapperField(this, order_v1_order_pb.Order, 1));
};


/**
 * @param {?proto.swift.order.v1.Order|undefined} value
 * @return {!proto.swift.email.v1.OrderUpdated} returns this
*/
proto.swift.email.v1.OrderUpdated.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.OrderUpdated} returns this
 */
proto.swift.email.v1.OrderUpdated.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.OrderUpdated.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.DocumentUploaded.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.DocumentUploaded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.DocumentUploaded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.DocumentUploaded.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: (f = msg.getDocument()) && document_v1_document_pb.DocumentMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.DocumentUploaded}
 */
proto.swift.email.v1.DocumentUploaded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.DocumentUploaded;
  return proto.swift.email.v1.DocumentUploaded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.DocumentUploaded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.DocumentUploaded}
 */
proto.swift.email.v1.DocumentUploaded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new document_v1_document_pb.DocumentMetaData;
      reader.readMessage(value,document_v1_document_pb.DocumentMetaData.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.DocumentUploaded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.DocumentUploaded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.DocumentUploaded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.DocumentUploaded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      document_v1_document_pb.DocumentMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.document.v1.DocumentMetaData document = 1;
 * @return {?proto.swift.document.v1.DocumentMetaData}
 */
proto.swift.email.v1.DocumentUploaded.prototype.getDocument = function() {
  return /** @type{?proto.swift.document.v1.DocumentMetaData} */ (
    jspb.Message.getWrapperField(this, document_v1_document_pb.DocumentMetaData, 1));
};


/**
 * @param {?proto.swift.document.v1.DocumentMetaData|undefined} value
 * @return {!proto.swift.email.v1.DocumentUploaded} returns this
*/
proto.swift.email.v1.DocumentUploaded.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.DocumentUploaded} returns this
 */
proto.swift.email.v1.DocumentUploaded.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.DocumentUploaded.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.InvoiceIssued.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.InvoiceIssued.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.InvoiceIssued} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.InvoiceIssued.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoice: (f = msg.getInvoice()) && invoicing_v1_invoice_pb.Invoice.toObject(includeInstance, f),
    issuerName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    gcpfilepath: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.InvoiceIssued}
 */
proto.swift.email.v1.InvoiceIssued.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.InvoiceIssued;
  return proto.swift.email.v1.InvoiceIssued.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.InvoiceIssued} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.InvoiceIssued}
 */
proto.swift.email.v1.InvoiceIssued.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new invoicing_v1_invoice_pb.Invoice;
      reader.readMessage(value,invoicing_v1_invoice_pb.Invoice.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuerName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGcpfilepath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.InvoiceIssued.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.InvoiceIssued.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.InvoiceIssued} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.InvoiceIssued.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      invoicing_v1_invoice_pb.Invoice.serializeBinaryToWriter
    );
  }
  f = message.getIssuerName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGcpfilepath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional swift.invoicing.v1.Invoice invoice = 1;
 * @return {?proto.swift.invoicing.v1.Invoice}
 */
proto.swift.email.v1.InvoiceIssued.prototype.getInvoice = function() {
  return /** @type{?proto.swift.invoicing.v1.Invoice} */ (
    jspb.Message.getWrapperField(this, invoicing_v1_invoice_pb.Invoice, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Invoice|undefined} value
 * @return {!proto.swift.email.v1.InvoiceIssued} returns this
*/
proto.swift.email.v1.InvoiceIssued.prototype.setInvoice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.InvoiceIssued} returns this
 */
proto.swift.email.v1.InvoiceIssued.prototype.clearInvoice = function() {
  return this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.InvoiceIssued.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string issuer_name = 2;
 * @return {string}
 */
proto.swift.email.v1.InvoiceIssued.prototype.getIssuerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.email.v1.InvoiceIssued} returns this
 */
proto.swift.email.v1.InvoiceIssued.prototype.setIssuerName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string gcpFilepath = 3;
 * @return {string}
 */
proto.swift.email.v1.InvoiceIssued.prototype.getGcpfilepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.email.v1.InvoiceIssued} returns this
 */
proto.swift.email.v1.InvoiceIssued.prototype.setGcpfilepath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.InvoiceOverdue.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.InvoiceOverdue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.InvoiceOverdue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.InvoiceOverdue.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoice: (f = msg.getInvoice()) && invoicing_v1_invoice_pb.Invoice.toObject(includeInstance, f),
    gcpfilepath: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.InvoiceOverdue}
 */
proto.swift.email.v1.InvoiceOverdue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.InvoiceOverdue;
  return proto.swift.email.v1.InvoiceOverdue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.InvoiceOverdue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.InvoiceOverdue}
 */
proto.swift.email.v1.InvoiceOverdue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new invoicing_v1_invoice_pb.Invoice;
      reader.readMessage(value,invoicing_v1_invoice_pb.Invoice.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGcpfilepath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.InvoiceOverdue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.InvoiceOverdue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.InvoiceOverdue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.InvoiceOverdue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      invoicing_v1_invoice_pb.Invoice.serializeBinaryToWriter
    );
  }
  f = message.getGcpfilepath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional swift.invoicing.v1.Invoice invoice = 1;
 * @return {?proto.swift.invoicing.v1.Invoice}
 */
proto.swift.email.v1.InvoiceOverdue.prototype.getInvoice = function() {
  return /** @type{?proto.swift.invoicing.v1.Invoice} */ (
    jspb.Message.getWrapperField(this, invoicing_v1_invoice_pb.Invoice, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Invoice|undefined} value
 * @return {!proto.swift.email.v1.InvoiceOverdue} returns this
*/
proto.swift.email.v1.InvoiceOverdue.prototype.setInvoice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.InvoiceOverdue} returns this
 */
proto.swift.email.v1.InvoiceOverdue.prototype.clearInvoice = function() {
  return this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.InvoiceOverdue.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string gcpFilepath = 2;
 * @return {string}
 */
proto.swift.email.v1.InvoiceOverdue.prototype.getGcpfilepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.email.v1.InvoiceOverdue} returns this
 */
proto.swift.email.v1.InvoiceOverdue.prototype.setGcpfilepath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.BookingQuote.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.BookingQuote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.BookingQuote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.BookingQuote.toObject = function(includeInstance, msg) {
  var f, obj = {
    booking: (f = msg.getBooking()) && booking_v1_booking_pb.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.BookingQuote}
 */
proto.swift.email.v1.BookingQuote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.BookingQuote;
  return proto.swift.email.v1.BookingQuote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.BookingQuote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.BookingQuote}
 */
proto.swift.email.v1.BookingQuote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new booking_v1_booking_pb.Booking;
      reader.readMessage(value,booking_v1_booking_pb.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.BookingQuote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.BookingQuote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.BookingQuote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.BookingQuote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      booking_v1_booking_pb.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.booking.v1.Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.email.v1.BookingQuote.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, booking_v1_booking_pb.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.email.v1.BookingQuote} returns this
*/
proto.swift.email.v1.BookingQuote.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.BookingQuote} returns this
 */
proto.swift.email.v1.BookingQuote.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.BookingQuote.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.Attachment.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.Attachment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.Attachment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.Attachment.toObject = function(includeInstance, msg) {
  var f, obj = {
    gcpfilepath: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.Attachment}
 */
proto.swift.email.v1.Attachment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.Attachment;
  return proto.swift.email.v1.Attachment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.Attachment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.Attachment}
 */
proto.swift.email.v1.Attachment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGcpfilepath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.Attachment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.Attachment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.Attachment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.Attachment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGcpfilepath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string gcpFilepath = 1;
 * @return {string}
 */
proto.swift.email.v1.Attachment.prototype.getGcpfilepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.email.v1.Attachment} returns this
 */
proto.swift.email.v1.Attachment.prototype.setGcpfilepath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.ShipmentCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.ShipmentCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.ShipmentCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.ShipmentCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipment: (f = msg.getShipment()) && shipment_v1_shipment_pb.Shipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.ShipmentCreated}
 */
proto.swift.email.v1.ShipmentCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.ShipmentCreated;
  return proto.swift.email.v1.ShipmentCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.ShipmentCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.ShipmentCreated}
 */
proto.swift.email.v1.ShipmentCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shipment_v1_shipment_pb.Shipment;
      reader.readMessage(value,shipment_v1_shipment_pb.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.ShipmentCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.ShipmentCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.ShipmentCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.ShipmentCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shipment_v1_shipment_pb.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.shipment.v1.Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.email.v1.ShipmentCreated.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, shipment_v1_shipment_pb.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.email.v1.ShipmentCreated} returns this
*/
proto.swift.email.v1.ShipmentCreated.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.ShipmentCreated} returns this
 */
proto.swift.email.v1.ShipmentCreated.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.ShipmentCreated.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.ShipmentEdited.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.ShipmentEdited.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.ShipmentEdited} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.ShipmentEdited.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipment: (f = msg.getShipment()) && shipment_v1_shipment_pb.Shipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.ShipmentEdited}
 */
proto.swift.email.v1.ShipmentEdited.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.ShipmentEdited;
  return proto.swift.email.v1.ShipmentEdited.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.ShipmentEdited} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.ShipmentEdited}
 */
proto.swift.email.v1.ShipmentEdited.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shipment_v1_shipment_pb.Shipment;
      reader.readMessage(value,shipment_v1_shipment_pb.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.ShipmentEdited.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.ShipmentEdited.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.ShipmentEdited} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.ShipmentEdited.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shipment_v1_shipment_pb.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.shipment.v1.Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.email.v1.ShipmentEdited.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, shipment_v1_shipment_pb.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.email.v1.ShipmentEdited} returns this
*/
proto.swift.email.v1.ShipmentEdited.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.ShipmentEdited} returns this
 */
proto.swift.email.v1.ShipmentEdited.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.ShipmentEdited.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.ShipmentBookingAdded.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.ShipmentBookingAdded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.ShipmentBookingAdded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.ShipmentBookingAdded.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipment: (f = msg.getShipment()) && shipment_v1_shipment_pb.Shipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.ShipmentBookingAdded}
 */
proto.swift.email.v1.ShipmentBookingAdded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.ShipmentBookingAdded;
  return proto.swift.email.v1.ShipmentBookingAdded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.ShipmentBookingAdded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.ShipmentBookingAdded}
 */
proto.swift.email.v1.ShipmentBookingAdded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shipment_v1_shipment_pb.Shipment;
      reader.readMessage(value,shipment_v1_shipment_pb.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.ShipmentBookingAdded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.ShipmentBookingAdded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.ShipmentBookingAdded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.ShipmentBookingAdded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shipment_v1_shipment_pb.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.shipment.v1.Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.email.v1.ShipmentBookingAdded.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, shipment_v1_shipment_pb.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.email.v1.ShipmentBookingAdded} returns this
*/
proto.swift.email.v1.ShipmentBookingAdded.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.ShipmentBookingAdded} returns this
 */
proto.swift.email.v1.ShipmentBookingAdded.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.ShipmentBookingAdded.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.ShipmentBookingRemoved.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.ShipmentBookingRemoved.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.ShipmentBookingRemoved} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.ShipmentBookingRemoved.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipment: (f = msg.getShipment()) && shipment_v1_shipment_pb.Shipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.ShipmentBookingRemoved}
 */
proto.swift.email.v1.ShipmentBookingRemoved.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.ShipmentBookingRemoved;
  return proto.swift.email.v1.ShipmentBookingRemoved.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.ShipmentBookingRemoved} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.ShipmentBookingRemoved}
 */
proto.swift.email.v1.ShipmentBookingRemoved.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shipment_v1_shipment_pb.Shipment;
      reader.readMessage(value,shipment_v1_shipment_pb.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.ShipmentBookingRemoved.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.ShipmentBookingRemoved.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.ShipmentBookingRemoved} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.ShipmentBookingRemoved.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shipment_v1_shipment_pb.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.shipment.v1.Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.email.v1.ShipmentBookingRemoved.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, shipment_v1_shipment_pb.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.email.v1.ShipmentBookingRemoved} returns this
*/
proto.swift.email.v1.ShipmentBookingRemoved.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.ShipmentBookingRemoved} returns this
 */
proto.swift.email.v1.ShipmentBookingRemoved.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.ShipmentBookingRemoved.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.ContractAccepted.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.ContractAccepted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.ContractAccepted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.ContractAccepted.toObject = function(includeInstance, msg) {
  var f, obj = {
    contract: (f = msg.getContract()) && contract_v1_contract_pb.Contract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.ContractAccepted}
 */
proto.swift.email.v1.ContractAccepted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.ContractAccepted;
  return proto.swift.email.v1.ContractAccepted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.ContractAccepted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.ContractAccepted}
 */
proto.swift.email.v1.ContractAccepted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new contract_v1_contract_pb.Contract;
      reader.readMessage(value,contract_v1_contract_pb.Contract.deserializeBinaryFromReader);
      msg.setContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.ContractAccepted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.ContractAccepted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.ContractAccepted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.ContractAccepted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      contract_v1_contract_pb.Contract.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.contract.v1.Contract contract = 1;
 * @return {?proto.swift.contract.v1.Contract}
 */
proto.swift.email.v1.ContractAccepted.prototype.getContract = function() {
  return /** @type{?proto.swift.contract.v1.Contract} */ (
    jspb.Message.getWrapperField(this, contract_v1_contract_pb.Contract, 1));
};


/**
 * @param {?proto.swift.contract.v1.Contract|undefined} value
 * @return {!proto.swift.email.v1.ContractAccepted} returns this
*/
proto.swift.email.v1.ContractAccepted.prototype.setContract = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.ContractAccepted} returns this
 */
proto.swift.email.v1.ContractAccepted.prototype.clearContract = function() {
  return this.setContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.ContractAccepted.prototype.hasContract = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.email.v1.Email.oneofGroups_ = [[5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21]];

/**
 * @enum {number}
 */
proto.swift.email.v1.Email.EventCase = {
  EVENT_NOT_SET: 0,
  BOOKING_IMPORT_FAILED: 5,
  BOOKING_CREATED: 6,
  BOOKING_EDITED: 7,
  BOOKING_QUOTE: 8,
  BOOKING_BOOKED: 9,
  BOOKING_ACCEPTED: 10,
  MESSAGE_CREATED: 11,
  ORDER_CREATED: 12,
  ORDER_UPDATED: 13,
  DOCUMENT_UPLOADED: 14,
  INVOICE_ISSUED: 15,
  INVOICE_OVERDUE: 16,
  SHIPMENT_CREATED: 17,
  SHIPMENT_EDITED: 18,
  SHIPMENT_BOOKING_ADDED: 19,
  SHIPMENT_BOOKING_REMOVED: 20,
  CONTRACT_ACCEPTED: 21
};

/**
 * @return {proto.swift.email.v1.Email.EventCase}
 */
proto.swift.email.v1.Email.prototype.getEventCase = function() {
  return /** @type {proto.swift.email.v1.Email.EventCase} */(jspb.Message.computeOneofCase(this, proto.swift.email.v1.Email.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.email.v1.Email.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.email.v1.Email.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.email.v1.Email} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.Email.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    toAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organization: jspb.Message.getFieldWithDefault(msg, 3, ""),
    orgDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bookingImportFailed: (f = msg.getBookingImportFailed()) && proto.swift.email.v1.BookingImportFailed.toObject(includeInstance, f),
    bookingCreated: (f = msg.getBookingCreated()) && proto.swift.email.v1.BookingCreated.toObject(includeInstance, f),
    bookingEdited: (f = msg.getBookingEdited()) && proto.swift.email.v1.BookingEdited.toObject(includeInstance, f),
    bookingQuote: (f = msg.getBookingQuote()) && proto.swift.email.v1.BookingQuote.toObject(includeInstance, f),
    bookingBooked: (f = msg.getBookingBooked()) && proto.swift.email.v1.BookingBooked.toObject(includeInstance, f),
    bookingAccepted: (f = msg.getBookingAccepted()) && proto.swift.email.v1.BookingAccepted.toObject(includeInstance, f),
    messageCreated: (f = msg.getMessageCreated()) && proto.swift.email.v1.MessageCreated.toObject(includeInstance, f),
    orderCreated: (f = msg.getOrderCreated()) && proto.swift.email.v1.OrderCreated.toObject(includeInstance, f),
    orderUpdated: (f = msg.getOrderUpdated()) && proto.swift.email.v1.OrderUpdated.toObject(includeInstance, f),
    documentUploaded: (f = msg.getDocumentUploaded()) && proto.swift.email.v1.DocumentUploaded.toObject(includeInstance, f),
    invoiceIssued: (f = msg.getInvoiceIssued()) && proto.swift.email.v1.InvoiceIssued.toObject(includeInstance, f),
    invoiceOverdue: (f = msg.getInvoiceOverdue()) && proto.swift.email.v1.InvoiceOverdue.toObject(includeInstance, f),
    shipmentCreated: (f = msg.getShipmentCreated()) && proto.swift.email.v1.ShipmentCreated.toObject(includeInstance, f),
    shipmentEdited: (f = msg.getShipmentEdited()) && proto.swift.email.v1.ShipmentEdited.toObject(includeInstance, f),
    shipmentBookingAdded: (f = msg.getShipmentBookingAdded()) && proto.swift.email.v1.ShipmentBookingAdded.toObject(includeInstance, f),
    shipmentBookingRemoved: (f = msg.getShipmentBookingRemoved()) && proto.swift.email.v1.ShipmentBookingRemoved.toObject(includeInstance, f),
    contractAccepted: (f = msg.getContractAccepted()) && proto.swift.email.v1.ContractAccepted.toObject(includeInstance, f),
    attachment: (f = msg.getAttachment()) && proto.swift.email.v1.Attachment.toObject(includeInstance, f),
    bccAddress: jspb.Message.getFieldWithDefault(msg, 23, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.email.v1.Email}
 */
proto.swift.email.v1.Email.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.email.v1.Email;
  return proto.swift.email.v1.Email.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.email.v1.Email} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.email.v1.Email}
 */
proto.swift.email.v1.Email.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.email.v1.Email.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganization(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgDescription(value);
      break;
    case 5:
      var value = new proto.swift.email.v1.BookingImportFailed;
      reader.readMessage(value,proto.swift.email.v1.BookingImportFailed.deserializeBinaryFromReader);
      msg.setBookingImportFailed(value);
      break;
    case 6:
      var value = new proto.swift.email.v1.BookingCreated;
      reader.readMessage(value,proto.swift.email.v1.BookingCreated.deserializeBinaryFromReader);
      msg.setBookingCreated(value);
      break;
    case 7:
      var value = new proto.swift.email.v1.BookingEdited;
      reader.readMessage(value,proto.swift.email.v1.BookingEdited.deserializeBinaryFromReader);
      msg.setBookingEdited(value);
      break;
    case 8:
      var value = new proto.swift.email.v1.BookingQuote;
      reader.readMessage(value,proto.swift.email.v1.BookingQuote.deserializeBinaryFromReader);
      msg.setBookingQuote(value);
      break;
    case 9:
      var value = new proto.swift.email.v1.BookingBooked;
      reader.readMessage(value,proto.swift.email.v1.BookingBooked.deserializeBinaryFromReader);
      msg.setBookingBooked(value);
      break;
    case 10:
      var value = new proto.swift.email.v1.BookingAccepted;
      reader.readMessage(value,proto.swift.email.v1.BookingAccepted.deserializeBinaryFromReader);
      msg.setBookingAccepted(value);
      break;
    case 11:
      var value = new proto.swift.email.v1.MessageCreated;
      reader.readMessage(value,proto.swift.email.v1.MessageCreated.deserializeBinaryFromReader);
      msg.setMessageCreated(value);
      break;
    case 12:
      var value = new proto.swift.email.v1.OrderCreated;
      reader.readMessage(value,proto.swift.email.v1.OrderCreated.deserializeBinaryFromReader);
      msg.setOrderCreated(value);
      break;
    case 13:
      var value = new proto.swift.email.v1.OrderUpdated;
      reader.readMessage(value,proto.swift.email.v1.OrderUpdated.deserializeBinaryFromReader);
      msg.setOrderUpdated(value);
      break;
    case 14:
      var value = new proto.swift.email.v1.DocumentUploaded;
      reader.readMessage(value,proto.swift.email.v1.DocumentUploaded.deserializeBinaryFromReader);
      msg.setDocumentUploaded(value);
      break;
    case 15:
      var value = new proto.swift.email.v1.InvoiceIssued;
      reader.readMessage(value,proto.swift.email.v1.InvoiceIssued.deserializeBinaryFromReader);
      msg.setInvoiceIssued(value);
      break;
    case 16:
      var value = new proto.swift.email.v1.InvoiceOverdue;
      reader.readMessage(value,proto.swift.email.v1.InvoiceOverdue.deserializeBinaryFromReader);
      msg.setInvoiceOverdue(value);
      break;
    case 17:
      var value = new proto.swift.email.v1.ShipmentCreated;
      reader.readMessage(value,proto.swift.email.v1.ShipmentCreated.deserializeBinaryFromReader);
      msg.setShipmentCreated(value);
      break;
    case 18:
      var value = new proto.swift.email.v1.ShipmentEdited;
      reader.readMessage(value,proto.swift.email.v1.ShipmentEdited.deserializeBinaryFromReader);
      msg.setShipmentEdited(value);
      break;
    case 19:
      var value = new proto.swift.email.v1.ShipmentBookingAdded;
      reader.readMessage(value,proto.swift.email.v1.ShipmentBookingAdded.deserializeBinaryFromReader);
      msg.setShipmentBookingAdded(value);
      break;
    case 20:
      var value = new proto.swift.email.v1.ShipmentBookingRemoved;
      reader.readMessage(value,proto.swift.email.v1.ShipmentBookingRemoved.deserializeBinaryFromReader);
      msg.setShipmentBookingRemoved(value);
      break;
    case 21:
      var value = new proto.swift.email.v1.ContractAccepted;
      reader.readMessage(value,proto.swift.email.v1.ContractAccepted.deserializeBinaryFromReader);
      msg.setContractAccepted(value);
      break;
    case 22:
      var value = new proto.swift.email.v1.Attachment;
      reader.readMessage(value,proto.swift.email.v1.Attachment.deserializeBinaryFromReader);
      msg.setAttachment(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setBccAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.email.v1.Email.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.email.v1.Email.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.email.v1.Email} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.email.v1.Email.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getToAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganization();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrgDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBookingImportFailed();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.swift.email.v1.BookingImportFailed.serializeBinaryToWriter
    );
  }
  f = message.getBookingCreated();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.swift.email.v1.BookingCreated.serializeBinaryToWriter
    );
  }
  f = message.getBookingEdited();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.swift.email.v1.BookingEdited.serializeBinaryToWriter
    );
  }
  f = message.getBookingQuote();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.swift.email.v1.BookingQuote.serializeBinaryToWriter
    );
  }
  f = message.getBookingBooked();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.swift.email.v1.BookingBooked.serializeBinaryToWriter
    );
  }
  f = message.getBookingAccepted();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.swift.email.v1.BookingAccepted.serializeBinaryToWriter
    );
  }
  f = message.getMessageCreated();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.swift.email.v1.MessageCreated.serializeBinaryToWriter
    );
  }
  f = message.getOrderCreated();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.swift.email.v1.OrderCreated.serializeBinaryToWriter
    );
  }
  f = message.getOrderUpdated();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.swift.email.v1.OrderUpdated.serializeBinaryToWriter
    );
  }
  f = message.getDocumentUploaded();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.swift.email.v1.DocumentUploaded.serializeBinaryToWriter
    );
  }
  f = message.getInvoiceIssued();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.swift.email.v1.InvoiceIssued.serializeBinaryToWriter
    );
  }
  f = message.getInvoiceOverdue();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.swift.email.v1.InvoiceOverdue.serializeBinaryToWriter
    );
  }
  f = message.getShipmentCreated();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.swift.email.v1.ShipmentCreated.serializeBinaryToWriter
    );
  }
  f = message.getShipmentEdited();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.swift.email.v1.ShipmentEdited.serializeBinaryToWriter
    );
  }
  f = message.getShipmentBookingAdded();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.swift.email.v1.ShipmentBookingAdded.serializeBinaryToWriter
    );
  }
  f = message.getShipmentBookingRemoved();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.swift.email.v1.ShipmentBookingRemoved.serializeBinaryToWriter
    );
  }
  f = message.getContractAccepted();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.swift.email.v1.ContractAccepted.serializeBinaryToWriter
    );
  }
  f = message.getAttachment();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.swift.email.v1.Attachment.serializeBinaryToWriter
    );
  }
  f = message.getBccAddress();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.email.v1.Email.Type = {
  BOOKING_IMPORT_FAILED: 0,
  BOOKING_CREATED: 1,
  BOOKING_UPDATED: 2,
  BOOKING_QUOTE: 3,
  BOOKING_BOOKED: 4,
  BOOKING_ACCEPTED: 5,
  MESSAGE_CREATED: 6,
  ORDER_CREATED: 7,
  ORDER_UPDATED: 8,
  DOCUMENT_UPLOADED: 9,
  INVOICE_ISSUED: 10,
  INVOICE_OVERDUE: 11,
  SHIPMENT_CREATED: 12,
  SHIPMENT_EDITED: 13,
  SHIPMENT_BOOKING_ADDED: 14,
  SHIPMENT_BOOKING_REMOVED: 15,
  CONTRACT_ACCEPTED: 16
};

/**
 * optional Type type = 1;
 * @return {!proto.swift.email.v1.Email.Type}
 */
proto.swift.email.v1.Email.prototype.getType = function() {
  return /** @type {!proto.swift.email.v1.Email.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.email.v1.Email.Type} value
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string to_address = 2;
 * @return {string}
 */
proto.swift.email.v1.Email.prototype.getToAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.setToAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organization = 3;
 * @return {string}
 */
proto.swift.email.v1.Email.prototype.getOrganization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.setOrganization = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string org_description = 4;
 * @return {string}
 */
proto.swift.email.v1.Email.prototype.getOrgDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.setOrgDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional BookingImportFailed booking_import_failed = 5;
 * @return {?proto.swift.email.v1.BookingImportFailed}
 */
proto.swift.email.v1.Email.prototype.getBookingImportFailed = function() {
  return /** @type{?proto.swift.email.v1.BookingImportFailed} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.BookingImportFailed, 5));
};


/**
 * @param {?proto.swift.email.v1.BookingImportFailed|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setBookingImportFailed = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearBookingImportFailed = function() {
  return this.setBookingImportFailed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasBookingImportFailed = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional BookingCreated booking_created = 6;
 * @return {?proto.swift.email.v1.BookingCreated}
 */
proto.swift.email.v1.Email.prototype.getBookingCreated = function() {
  return /** @type{?proto.swift.email.v1.BookingCreated} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.BookingCreated, 6));
};


/**
 * @param {?proto.swift.email.v1.BookingCreated|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setBookingCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearBookingCreated = function() {
  return this.setBookingCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasBookingCreated = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional BookingEdited booking_edited = 7;
 * @return {?proto.swift.email.v1.BookingEdited}
 */
proto.swift.email.v1.Email.prototype.getBookingEdited = function() {
  return /** @type{?proto.swift.email.v1.BookingEdited} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.BookingEdited, 7));
};


/**
 * @param {?proto.swift.email.v1.BookingEdited|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setBookingEdited = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearBookingEdited = function() {
  return this.setBookingEdited(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasBookingEdited = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional BookingQuote booking_quote = 8;
 * @return {?proto.swift.email.v1.BookingQuote}
 */
proto.swift.email.v1.Email.prototype.getBookingQuote = function() {
  return /** @type{?proto.swift.email.v1.BookingQuote} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.BookingQuote, 8));
};


/**
 * @param {?proto.swift.email.v1.BookingQuote|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setBookingQuote = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearBookingQuote = function() {
  return this.setBookingQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasBookingQuote = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional BookingBooked booking_booked = 9;
 * @return {?proto.swift.email.v1.BookingBooked}
 */
proto.swift.email.v1.Email.prototype.getBookingBooked = function() {
  return /** @type{?proto.swift.email.v1.BookingBooked} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.BookingBooked, 9));
};


/**
 * @param {?proto.swift.email.v1.BookingBooked|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setBookingBooked = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearBookingBooked = function() {
  return this.setBookingBooked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasBookingBooked = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional BookingAccepted booking_accepted = 10;
 * @return {?proto.swift.email.v1.BookingAccepted}
 */
proto.swift.email.v1.Email.prototype.getBookingAccepted = function() {
  return /** @type{?proto.swift.email.v1.BookingAccepted} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.BookingAccepted, 10));
};


/**
 * @param {?proto.swift.email.v1.BookingAccepted|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setBookingAccepted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearBookingAccepted = function() {
  return this.setBookingAccepted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasBookingAccepted = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional MessageCreated message_created = 11;
 * @return {?proto.swift.email.v1.MessageCreated}
 */
proto.swift.email.v1.Email.prototype.getMessageCreated = function() {
  return /** @type{?proto.swift.email.v1.MessageCreated} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.MessageCreated, 11));
};


/**
 * @param {?proto.swift.email.v1.MessageCreated|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setMessageCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearMessageCreated = function() {
  return this.setMessageCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasMessageCreated = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional OrderCreated order_created = 12;
 * @return {?proto.swift.email.v1.OrderCreated}
 */
proto.swift.email.v1.Email.prototype.getOrderCreated = function() {
  return /** @type{?proto.swift.email.v1.OrderCreated} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.OrderCreated, 12));
};


/**
 * @param {?proto.swift.email.v1.OrderCreated|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setOrderCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearOrderCreated = function() {
  return this.setOrderCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasOrderCreated = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional OrderUpdated order_updated = 13;
 * @return {?proto.swift.email.v1.OrderUpdated}
 */
proto.swift.email.v1.Email.prototype.getOrderUpdated = function() {
  return /** @type{?proto.swift.email.v1.OrderUpdated} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.OrderUpdated, 13));
};


/**
 * @param {?proto.swift.email.v1.OrderUpdated|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setOrderUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearOrderUpdated = function() {
  return this.setOrderUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasOrderUpdated = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional DocumentUploaded document_uploaded = 14;
 * @return {?proto.swift.email.v1.DocumentUploaded}
 */
proto.swift.email.v1.Email.prototype.getDocumentUploaded = function() {
  return /** @type{?proto.swift.email.v1.DocumentUploaded} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.DocumentUploaded, 14));
};


/**
 * @param {?proto.swift.email.v1.DocumentUploaded|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setDocumentUploaded = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearDocumentUploaded = function() {
  return this.setDocumentUploaded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasDocumentUploaded = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional InvoiceIssued invoice_issued = 15;
 * @return {?proto.swift.email.v1.InvoiceIssued}
 */
proto.swift.email.v1.Email.prototype.getInvoiceIssued = function() {
  return /** @type{?proto.swift.email.v1.InvoiceIssued} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.InvoiceIssued, 15));
};


/**
 * @param {?proto.swift.email.v1.InvoiceIssued|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setInvoiceIssued = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearInvoiceIssued = function() {
  return this.setInvoiceIssued(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasInvoiceIssued = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional InvoiceOverdue invoice_overdue = 16;
 * @return {?proto.swift.email.v1.InvoiceOverdue}
 */
proto.swift.email.v1.Email.prototype.getInvoiceOverdue = function() {
  return /** @type{?proto.swift.email.v1.InvoiceOverdue} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.InvoiceOverdue, 16));
};


/**
 * @param {?proto.swift.email.v1.InvoiceOverdue|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setInvoiceOverdue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearInvoiceOverdue = function() {
  return this.setInvoiceOverdue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasInvoiceOverdue = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional ShipmentCreated shipment_created = 17;
 * @return {?proto.swift.email.v1.ShipmentCreated}
 */
proto.swift.email.v1.Email.prototype.getShipmentCreated = function() {
  return /** @type{?proto.swift.email.v1.ShipmentCreated} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.ShipmentCreated, 17));
};


/**
 * @param {?proto.swift.email.v1.ShipmentCreated|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setShipmentCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearShipmentCreated = function() {
  return this.setShipmentCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasShipmentCreated = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional ShipmentEdited shipment_edited = 18;
 * @return {?proto.swift.email.v1.ShipmentEdited}
 */
proto.swift.email.v1.Email.prototype.getShipmentEdited = function() {
  return /** @type{?proto.swift.email.v1.ShipmentEdited} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.ShipmentEdited, 18));
};


/**
 * @param {?proto.swift.email.v1.ShipmentEdited|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setShipmentEdited = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearShipmentEdited = function() {
  return this.setShipmentEdited(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasShipmentEdited = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional ShipmentBookingAdded shipment_booking_added = 19;
 * @return {?proto.swift.email.v1.ShipmentBookingAdded}
 */
proto.swift.email.v1.Email.prototype.getShipmentBookingAdded = function() {
  return /** @type{?proto.swift.email.v1.ShipmentBookingAdded} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.ShipmentBookingAdded, 19));
};


/**
 * @param {?proto.swift.email.v1.ShipmentBookingAdded|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setShipmentBookingAdded = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearShipmentBookingAdded = function() {
  return this.setShipmentBookingAdded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasShipmentBookingAdded = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional ShipmentBookingRemoved shipment_booking_removed = 20;
 * @return {?proto.swift.email.v1.ShipmentBookingRemoved}
 */
proto.swift.email.v1.Email.prototype.getShipmentBookingRemoved = function() {
  return /** @type{?proto.swift.email.v1.ShipmentBookingRemoved} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.ShipmentBookingRemoved, 20));
};


/**
 * @param {?proto.swift.email.v1.ShipmentBookingRemoved|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setShipmentBookingRemoved = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearShipmentBookingRemoved = function() {
  return this.setShipmentBookingRemoved(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasShipmentBookingRemoved = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional ContractAccepted contract_accepted = 21;
 * @return {?proto.swift.email.v1.ContractAccepted}
 */
proto.swift.email.v1.Email.prototype.getContractAccepted = function() {
  return /** @type{?proto.swift.email.v1.ContractAccepted} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.ContractAccepted, 21));
};


/**
 * @param {?proto.swift.email.v1.ContractAccepted|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setContractAccepted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.swift.email.v1.Email.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearContractAccepted = function() {
  return this.setContractAccepted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasContractAccepted = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional Attachment attachment = 22;
 * @return {?proto.swift.email.v1.Attachment}
 */
proto.swift.email.v1.Email.prototype.getAttachment = function() {
  return /** @type{?proto.swift.email.v1.Attachment} */ (
    jspb.Message.getWrapperField(this, proto.swift.email.v1.Attachment, 22));
};


/**
 * @param {?proto.swift.email.v1.Attachment|undefined} value
 * @return {!proto.swift.email.v1.Email} returns this
*/
proto.swift.email.v1.Email.prototype.setAttachment = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.clearAttachment = function() {
  return this.setAttachment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.email.v1.Email.prototype.hasAttachment = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional string bcc_address = 23;
 * @return {string}
 */
proto.swift.email.v1.Email.prototype.getBccAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.email.v1.Email} returns this
 */
proto.swift.email.v1.Email.prototype.setBccAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


goog.object.extend(exports, proto.swift.email.v1);
