import * as bookingv1 from 'proto/booking/v1/booking_pb'
import * as commonOwner from 'proto/common/owner_pb'

import { ActionsUnion, createAction } from '../../helpers'

import * as bookingTypes from 'types/booking'
import { BookingUiType } from 'types/bookingui'

export const ACCEPT_BOOKING_ERR = 'swift2/booking/ACCEPT_BOOKING_ERR'
export const ACCEPT_BOOKING_REQ = 'swift2/booking/ACCEPT_BOOKING_REQ'
export const ACCEPT_BOOKING_RESP = 'swift2/booking/ACCEPT_BOOKING_RESP'
export const CREATE_ERR = 'swift2/booking/CREATE_ERR'
export const CREATE_REQ = 'swift2/booking/CREATE_REQ'
export const CREATE_RESP = 'swift2/booking/CREATE_RESP'
export const DELETE_ERR = 'swift2/booking/DELETE_ERR'
export const DELETE_REQ = 'swift2/booking/DELETE_REQ'
export const DELETE_RESP = 'swift2/booking/DELETE_RESP'
export const EDIT_BOOKING_REQ = 'swift2/booking/EDIT_BOOKING_REQ'
export const EDIT_BOOKING_RESP = 'swift2/booking/EDIT_BOOKING_RESP'
export const EDIT_BOOKING_ERR = 'swift2/booking/EDIT_BOOKING_ERR'
export const GET_ERR = 'swift2/booking/GET_ERR'
export const GET_EVENTS_ERR = 'swift2/booking/GET_EVENTS_ERR'
export const GET_EVENTS_REQ = 'swift2/booking/GET_EVENTS_REQ'
export const GET_EVENTS_RESP = 'swift2/booking/GET_EVENTS_RESP'
export const GET_REQ = 'swift2/booking/GET_REQ'
export const GET_RESP = 'swift2/booking/GET_RESP'
export const GET_STATS_ERR = 'swift2/booking/GET_STATS_ERR'
export const GET_STATS_REQ = 'swift2/booking/GET_STATS_REQ'
export const GET_STATS_RESP = 'swift2/booking/GET_STATS_RESP'
export const LIST_ERR = 'swift2/booking/LIST_ERR'
export const LIST_REQ = 'swift2/booking/LIST_REQ'
export const LIST_RESP = 'swift2/booking/LIST_RESP'
export const QUOTE_BOOKING_ERR = 'swift2/booking/QUOTE_BOOKING_ERR'
export const QUOTE_BOOKING_REQ = 'swift2/booking/QUOTE_BOOKING_REQ'
export const QUOTE_BOOKING_RESP = 'swift2/booking/QUOTE_BOOKING_RESP'
export const GET_QUOTES_ERR = 'swift2/booking/GET_QUOTES_ERR'
export const GET_QUOTES_REQ = 'swift2/booking/GET_QUOTES_REQ'
export const GET_QUOTES_RESP = 'swift2/booking/GET_QUOTES_RESP'
export const UPDATE_BOOKING_ITEMS_ERR = 'swift2/booking/UPDATE_BOOKING_ITEMS_ERR'
export const UPDATE_BOOKING_ITEMS_REQ = 'swift2/booking/UPDATE_BOOKING_ITEMS_REQ'
export const UPDATE_BOOKING_ITEMS_RESP = 'swift2/booking/UPDATE_BOOKING_ITEMS_RESP'
export const UPDATE_ESTIMATES_ERR = 'swift2/booking/UPDATE_ESTIMATES_ERR'
export const UPDATE_ESTIMATES_REQ = 'swift2/booking/UPDATE_ESTIMATES_REQ'
export const UPDATE_ESTIMATES_RESP = 'swift2/booking/UPDATE_ESTIMATES_RESP'
export const UPDATE_INTERNAL_NOTE_ERR = 'swift2/booking/UPDATE_INTERNAL_NOTE_ERR'
export const UPDATE_INTERNAL_NOTE_REQ = 'swift2/booking/UPDATE_INTERNAL_NOTE_REQ'
export const UPDATE_INTERNAL_NOTE_RESP = 'swift2/booking/UPDATE_INTERNAL_NOTE_RESP'
export const UPDATE_TRANSPORT_REF_ERR = 'swift2/booking/UPDATE_TRANSPORT_REF_ERR'
export const UPDATE_TRANSPORT_REF_REQ = 'swift2/booking/UPDATE_TRANSPORT_REF_REQ'
export const UPDATE_TRANSPORT_REF_RESP = 'swift2/booking/UPDATE_TRANSPORT_REF_RESP'
export const UPDATE_TRANSPORT_OPERATOR_ERR = 'swift2/booking/UPDATE_TRANSPORT_OPERATOR_ERR'
export const UPDATE_TRANSPORT_OPERATOR_REQ = 'swift2/booking/UPDATE_TRANSPORT_OPERATOR_REQ'
export const UPDATE_TRANSPORT_OPERATOR_RESP = 'swift2/booking/UPDATE_TRANSPORT_OPERATOR_RESP'
export const UPDATE_STATUS_ERR = 'swift2/booking/UPDATE_STATUS_ERR'
export const UPDATE_STATUS_REQ = 'swift2/booking/UPDATE_STATUS_REQ'
export const UPDATE_STATUS_RESP = 'swift2/booking/UPDATE_STATUS_RESP'
export const UPDATE_REQUIREMENTS_ERR = 'swift2/booking/UPDATE_REQUIREMENTS_ERR'
export const UPDATE_REQUIREMENTS_REQ = 'swift2/booking/UPDATE_REQUIREMENTS_REQ'
export const UPDATE_REQUIREMENTS_RESP = 'swift2/booking/UPDATE_REQUIREMENTS_RESP'
export const UPDATE_UNIT_ERR = 'swift2/booking/UPDATE_UNIT_ERR'
export const UPDATE_UNIT_REQ = 'swift2/booking/UPDATE_UNIT_REQ'
export const UPDATE_UNIT_RESP = 'swift2/booking/UPDATE_UNIT_RESP'
export const SET_BOOKING_PRICE_REQ = 'swift2/booking/SET_BOOKING_PRICE_REQ'
export const SET_BOOKING_PRICE_RESP = 'swift2/booking/SET_BOOKING_PRICE_RESP'
export const SET_BOOKING_PRICE_ERR = 'swift2/booking/SET_BOOKING_PRICE_ERR'
export const SET_ACTION_REQ = 'swift2/booking/SET_ACTION_REQ'
export const SET_ACTION_RESP = 'swift2/booking/SET_ACTION_RESP'
export const SET_ACTION_ERR = 'swift2/booking/SET_ACTION_ERR'
export const SET_FINANCIAL_MONTH_REQ = 'swift2/booking/SET_FINANCIAL_MONTH_REQ'
export const SET_FINANCIAL_MONTH_RESP = 'swift2/booking/SET_FINANCIAL_MONTH_RESP'
export const SET_FINANCIAL_MONTH_ERR = 'swift2/booking/SET_FINANCIAL_MONTH_ERR'

export const Actions = {
  acceptBookingErr: (err: Error) => createAction(ACCEPT_BOOKING_ERR, { err }),
  acceptBookingReq: (
    bookingRef: string,
    useContract: boolean,
    dataCase: bookingv1.AcceptBookingRequest.DataCase,
    navFunc: (path: string, bookingResponse: bookingv1.Booking) => void,
    acceptDirectSupplier?: bookingv1.AcceptDirectSupplier,
    acceptManagedSupplier?: bookingv1.AcceptManagedSupplier,
  ) =>
    createAction(ACCEPT_BOOKING_REQ, {
      bookingRef,
      useContract,
      dataCase,
      acceptDirectSupplier,
      acceptManagedSupplier,
      navFunc,
    }),
  acceptBookingResp: (booking: bookingv1.Booking) => createAction(ACCEPT_BOOKING_RESP, { booking }),
  createBookingErr: (err: Error) => createAction(CREATE_ERR, { err }),
  createBookingReq: (
    booking: bookingv1.Booking,
    asQuote: boolean,
    navFunc: (path: string) => void,
  ) => createAction(CREATE_REQ, { booking, asQuote, navFunc }),
  createBookingResp: (booking: bookingv1.Booking) => createAction(CREATE_RESP, { booking }),
  deleteBookingErr: (err: Error) => createAction(DELETE_ERR, { err }),
  deleteBookingReq: (bookingID: number, status: bookingv1.Booking.Status) =>
    createAction(DELETE_REQ, {
      bookingID,
      status,
    }),
  deleteBookingResp: (bookingID: number) => createAction(DELETE_RESP, { bookingID }),
  editBookingReq: (booking: bookingv1.Booking) => createAction(EDIT_BOOKING_REQ, { booking }),
  editBookingResp: (booking: bookingv1.Booking) => createAction(EDIT_BOOKING_RESP, { booking }),
  editBookingErr: (err: Error) => createAction(EDIT_BOOKING_ERR, { err }),
  updateInternalNoteErr: (err: Error) => createAction(UPDATE_INTERNAL_NOTE_ERR, { err }),
  updateInternalNoteReq: (booking: bookingv1.Booking) =>
    createAction(UPDATE_INTERNAL_NOTE_REQ, { booking }),
  updateInternalNoteResp: (booking: bookingv1.Booking) =>
    createAction(UPDATE_INTERNAL_NOTE_RESP, { booking }),
  updateBookingItemsErr: (err: Error) => createAction(UPDATE_BOOKING_ITEMS_ERR, { err }),
  updateBookingItemsReq: (booking: bookingv1.Booking, reason: string) =>
    createAction(UPDATE_BOOKING_ITEMS_REQ, { booking, reason }),
  updateBookingItemsResp: (booking: bookingv1.Booking) =>
    createAction(UPDATE_BOOKING_ITEMS_RESP, { booking }),
  getBookingErr: (err: Error) => createAction(GET_ERR, { err }),
  getBookingReq: (ref: string, fetchLinkedItems: boolean, fetchLinkedEvents: boolean) =>
    createAction(GET_REQ, {
      ref,
      fetchLinkedItems,
      fetchLinkedEvents,
    }),

  getBookingResp: (booking?: bookingv1.Booking) => createAction(GET_RESP, { booking }),
  getBookingStatsErr: (err: Error) => createAction(GET_STATS_ERR, { err }),
  getBookingStatsReq: (query: bookingv1.BookingStatsQuery) =>
    createAction(GET_STATS_REQ, { query }),
  getBookingStatsResp: (stats: bookingv1.BookingStats) => createAction(GET_STATS_RESP, { stats }),
  getEventsErr: (err: Error) => createAction(GET_EVENTS_ERR, { err }),
  getEventsReq: (bookingRef: string) => createAction(GET_EVENTS_REQ, { bookingRef }),
  getEventsResp: (events: Array<bookingv1.BookingEvent>, bookingRef: string) =>
    createAction(GET_EVENTS_RESP, {
      events,
      bookingRef,
    }),
  listBookingsErr: (err: Error) => createAction(LIST_ERR, { err }),
  listBookingsReq: (
    page: bookingTypes.BookingPage,
    fetchInvoices?: boolean,
    skipSubmodes?: boolean,
  ) => createAction(LIST_REQ, { page, fetchInvoices, skipSubmodes }),
  listBookingsResp: (
    count: number,
    bookings: bookingv1.Booking[],
    page: bookingTypes.BookingPage,
  ) =>
    createAction(LIST_RESP, {
      count,
      bookings,
      page,
    }),
  quoteBookingErr: (err: Error) => createAction(QUOTE_BOOKING_ERR, { err }),
  quoteBookingReq: (bookingRef: string, bookingID: number) =>
    createAction(QUOTE_BOOKING_REQ, { bookingRef, bookingID }),
  quoteBookingResp: (booking: bookingv1.Booking) => createAction(QUOTE_BOOKING_RESP, { booking }),
  getQuotesErr: (err: Error) => createAction(GET_QUOTES_ERR, { err }),
  getQuotesReq: (bookingRef: string, bookingID: number) =>
    createAction(GET_QUOTES_REQ, { bookingRef, bookingID }),
  getQuotesResp: () => createAction(GET_QUOTES_RESP, {}),
  updateEstimatesErr: (err: Error) => createAction(UPDATE_ESTIMATES_ERR, { err }),
  updateEstimatesReq: (booking: bookingv1.Booking, uiConfig: BookingUiType) =>
    createAction(UPDATE_ESTIMATES_REQ, {
      booking,
      uiConfig,
    }),
  updateEstimatesResp: (booking: bookingv1.Booking) =>
    createAction(UPDATE_ESTIMATES_RESP, { booking }),
  updateTransportRefErr: (err: Error) => createAction(UPDATE_TRANSPORT_REF_ERR, { err }),
  updateTransportRefReq: (bookingID: number, transportRef: string) =>
    createAction(UPDATE_TRANSPORT_REF_REQ, {
      bookingID,
      transportRef,
    }),
  updateTransportRefResp: (booking: bookingv1.Booking) =>
    createAction(UPDATE_TRANSPORT_REF_RESP, { booking }),
  updateStatusErr: (err: Error) => createAction(UPDATE_STATUS_ERR, { err }),
  updateStatusReq: (newStatus: bookingv1.BookingEvent) =>
    createAction(UPDATE_STATUS_REQ, { newStatus }),
  updateStatusResp: (booking: bookingv1.Booking) => createAction(UPDATE_STATUS_RESP, { booking }),
  updateRequirementsErr: (err: Error) => createAction(UPDATE_REQUIREMENTS_ERR, { err }),
  updateRequirementsReq: (booking: bookingv1.Booking) =>
    createAction(UPDATE_REQUIREMENTS_REQ, {
      booking,
    }),
  updateRequirementsResp: (booking: bookingv1.Booking) =>
    createAction(UPDATE_REQUIREMENTS_RESP, { booking }),

  setBookingPriceErr: (err: Error) => createAction(SET_BOOKING_PRICE_ERR, { err }),
  setBookingPriceReq: (bookingID: number, bookingPrice: number, bookingPriceCurrency: string) =>
    createAction(SET_BOOKING_PRICE_REQ, {
      bookingID,
      bookingPrice,
      bookingPriceCurrency,
    }),
  setBookingPriceResp: (booking: bookingv1.Booking) =>
    createAction(SET_BOOKING_PRICE_RESP, { booking }),

  updateTransportOperatorErr: (err: Error) => createAction(UPDATE_TRANSPORT_OPERATOR_ERR, { err }),
  updateTransportOperatorReq: (bookingID: number, owner: commonOwner.Owner) =>
    createAction(UPDATE_TRANSPORT_OPERATOR_REQ, { bookingID, owner }),
  updateTransportOperatorResp: (booking: bookingv1.Booking) =>
    createAction(UPDATE_TRANSPORT_OPERATOR_RESP, { booking }),

  setActionErr: (err: Error) => createAction(SET_ACTION_ERR, { err }),
  setActionReq: (booking: bookingv1.Booking) => createAction(SET_ACTION_REQ, { booking }),
  setActionResp: (booking: bookingv1.Booking) => createAction(SET_ACTION_RESP, { booking }),

  setFinancialMonthErr: (err: Error) => createAction(SET_FINANCIAL_MONTH_ERR, { err }),
  setFinancialMonthReq: (booking: bookingv1.Booking) =>
    createAction(SET_FINANCIAL_MONTH_REQ, { booking }),
  setFinancialMonthResp: (booking: bookingv1.Booking) =>
    createAction(SET_FINANCIAL_MONTH_RESP, { booking }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
