import React from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Card, PageHeader, Spin } from 'antd'

import './style.css'

interface GeneralViewCardProps {
  readonly header: {
    title: string
    subTitle?: string
    extra?: React.ReactNode
  }
  readonly leftActions?: React.ReactNode
  readonly rightActions?: React.ReactNode
  readonly children: React.ReactNode
  readonly fetching?: boolean
}

interface CardHeaderProps {
  readonly title: string
  readonly subTitle?: string
  readonly extra?: React.ReactNode
}

export const CardHeader: React.FC<CardHeaderProps> = ({ title, subTitle, extra }) => {
  return (
    <PageHeader
      className="general-card--no-padding"
      title={title}
      subTitle={subTitle}
      extra={[extra]}
    />
  )
}

// Use this component for views accessed through the menu (Admin is the exception here)
export const GeneralViewCard: React.FC<GeneralViewCardProps> = ({
  header,
  children,
  fetching,
  leftActions,
  rightActions,
}) => {
  const { title = '', subTitle, extra } = header

  return (
    <Card className="general">
      <CardHeader title={title} subTitle={subTitle} extra={extra} />
      {leftActions || rightActions ? (
        <div className="general-view-card-actions">
          <div className="general__actions">{!!leftActions && leftActions}</div>
          <div className="general__actions">{!!rightActions && rightActions}</div>
        </div>
      ) : null}

      {typeof fetching !== undefined && fetching ? (
        <div style={{ textAlign: 'center', margin: 48 }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin={true} />} />
        </div>
      ) : (
        children
      )}
    </Card>
  )
}

interface CommonLayoutProps {
  children: JSX.Element
}
export const CommonLayout = ({ children }: CommonLayoutProps) => {
  return <Card className="general">{children}</Card>
}
