import React, { useEffect } from 'react'

import { Form, Select } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { Rule } from 'antd/lib/form'

import '../Inputs/components/Input.css'
import { getInputClassName } from 'components/Inputs/helpers/Input.helper'
import { InputCommonProps } from 'components/Inputs/types/InputCommonProps.interface'
import { INPUTSIZES } from 'components/Inputs/types/InputSize.type'

import { onFilter } from './select.helper'

import './SelectComponent.css'

interface SelectItem {
  label: string
  key: string | number
  value?: string | number
}

interface SelectComponentProps extends InputCommonProps {
  label?: string
  formName?: string
  items: SelectItem[]
  value?: string | number
  formRef?: FormInstance<any>
  rules?: Rule[]
  placeholder?: string
  showSearch?: boolean
  narrow?: boolean
  onChange?: (value: string | number) => void
}

export const SelectComponent = ({
  value,
  label,
  formName,
  formRef,
  items,
  size = INPUTSIZES.extraExtraLarge,
  disabled,
  rules,
  showSearch = false,
  placeholder,
  narrow,
  onChange,
}: SelectComponentProps) => {
  useEffect(() => {
    if (formRef && formName && value) {
      formRef.setFieldsValue({
        [formName]: value,
      })
    }
  }, [value])

  return formName ? (
    <Form.Item
      name={formName}
      label={label}
      className={getInputClassName(size) + (narrow ? ' select-component--no-padding-no-argin' : '')}
      rules={rules}
      required={rules && rules.length > 0}
    >
      <Select
        showSearch={showSearch}
        onChange={onChange}
        disabled={disabled}
        value={value}
        className={getInputClassName(size)}
        filterOption={onFilter}
        options={items}
        placeholder={placeholder}
      />
    </Form.Item>
  ) : (
    <Select
      showSearch={showSearch}
      onChange={onChange}
      disabled={disabled}
      defaultValue={value}
      className={getInputClassName(size)}
      filterOption={onFilter}
      options={items}
      placeholder={placeholder}
    />
  )
}
